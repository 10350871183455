import {
  Container,
  EContainerBackground,
  ERowSpacing,
  ESectionBackground,
  toImageProps,
} from '@front/shared/ds';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import type { TSectionWrapperProps } from '../../types';

export const BlockWithRows: React.FC<TSectionWrapperProps> = ({
  children,
  rowSpacingY,
  blockWithRows,
  containerVariant,
  background = ESectionBackground.White,
  containerBackground = EContainerBackground.Transparent,
  isNoPaddingOnMobile,
}) => {
  const bgImage = blockWithRows?.background
    ? toImageProps(blockWithRows.background)
    : null;

  const bgMobileImage = blockWithRows?.backgroundMobile
    ? toImageProps(blockWithRows.backgroundMobile)
    : null;

  const hasSectionPadding = background !== ESectionBackground.Transparent;

  const hasContainerPadding =
    containerBackground !== EContainerBackground.Transparent &&
    background.toString() !== containerBackground.toString();

  return (
    <div
      className={clsx(
        'relative bg-interface-1000',
        'pt-10 md:pt-[4.5rem]',
        'pb-10 md:pb-12 xl:pb-[4.5rem]',
      )}
    >
      {bgImage ? (
        <Image
          {...bgImage}
          alt={bgImage.alt}
          className={clsx(
            'absolute left-0 top-0 h-full w-full object-cover object-left-top',
            { 'hidden md:block': !!bgMobileImage },
          )}
        />
      ) : null}

      {bgMobileImage ? (
        <Image
          {...bgMobileImage}
          alt={bgMobileImage.alt}
          className={clsx(
            'absolute left-0 top-0 h-full w-full object-cover object-left-top',
            { 'md:hidden': !!bgImage },
          )}
        />
      ) : null}

      <Container
        variant={containerVariant}
        isPadding={hasSectionPadding && hasContainerPadding}
        isNoPaddingOnMobile={isNoPaddingOnMobile}
      >
        <div
          className={clsx('relative flex w-full flex-col', {
            'gap-4': rowSpacingY === ERowSpacing.Xs,
            'gap-4 2xl:gap-8': rowSpacingY === ERowSpacing.Base,
            'gap-6 2xl:gap-8': rowSpacingY === ERowSpacing.Md,
            'gap-10 2xl:gap-12': rowSpacingY === ERowSpacing.Lg,
            'gap-8 2xl:gap-16': rowSpacingY === ERowSpacing.Xl,
          })}
        >
          {children}
        </div>
      </Container>
    </div>
  );
};
