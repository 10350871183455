import clsx from 'clsx';
import React, { ReactNode } from 'react';

import {
  EContainerBackground,
  EContainerMobileRadius,
  EContainerVariant,
} from './Container.constants';

export interface IContainerProps {
  children: ReactNode;
  variant?: EContainerVariant;
  containerBackground?: EContainerBackground;
  isContainerRounded?: boolean;
  containerRadiusOnMobile?: EContainerMobileRadius;
  isPadding?: boolean;
  isNoPaddingOnMobile?: boolean;
  dataGaId?: string;
  overflowHidden?: boolean;
}

const Container: React.FC<IContainerProps> = props => {
  const {
    children,
    variant = EContainerVariant.Default,
    containerBackground = EContainerBackground.Transparent,
    isContainerRounded = true,
    containerRadiusOnMobile,
    isPadding = true,
    isNoPaddingOnMobile,
    dataGaId,
    overflowHidden = false,
  } = props;

  let borderRadius = 'rounded-2xl lg:rounded-[2rem]';
  if (containerRadiusOnMobile === EContainerMobileRadius.None) {
    borderRadius = 'lg:rounded-[2rem]';
  }
  if (containerRadiusOnMobile === EContainerMobileRadius.Xl2) {
    borderRadius = 'rounded-2xl lg:rounded-[2rem]';
  }
  if (containerRadiusOnMobile === EContainerMobileRadius.Xl3) {
    borderRadius = 'rounded-3xl lg:rounded-[2rem]';
  }
  if (containerRadiusOnMobile === EContainerMobileRadius.Default) {
    borderRadius = 'rounded-[2rem]';
  }

  return (
    <div
      className={clsx(
        'm-auto w-full',
        isPadding && '2xl:px-4',
        isPadding && !isNoPaddingOnMobile && 'px-4',
        variant === EContainerVariant.Default && 'max-w-[80.5rem]',
        variant === EContainerVariant.Main && 'max-w-[79rem]',
        variant === EContainerVariant.Lead && 'max-w-[86rem]',
        variant === EContainerVariant.DoubleLead && 'max-w-[90rem]',
        variant === EContainerVariant.Full && 'max-w-full',
        overflowHidden && 'overflow-hidden',
        containerBackground === EContainerBackground.White && 'bg-surface-50',
        containerBackground === EContainerBackground.Dark && 'bg-black',
        containerBackground === EContainerBackground.Light && 'bg-surface-100',
        isContainerRounded && borderRadius,
      )}
      data-ga-id={dataGaId}
    >
      {children}
    </div>
  );
};

export default Container;
