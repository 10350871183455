import { cva } from 'cva';

export const enum ESectionTitleType {
  A = 'A',
  B = 'B',
}

export const enum ESectionTitleAlignContent {
  Center = 'Center',
  Start = 'Start',
}

export const enum ESectionTitleTheme {
  SectionTitle1 = 'SectionTitle1',
  SectionTitle2 = 'SectionTitle2',
  SectionTitle3 = 'SectionTitle3',
  SectionTitle4 = 'SectionTitle4',
  SectionTitle5 = 'SectionTitle5',
}

export const enum ETitleSpacing {
  Sm = 'sm',
  Base = 'base',
  Md = 'md',
  Lg = 'lg',
  Xxl = '2xl',
}

export const enum ESectionTitleContentWidth {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export const sectionTitleCVA = cva('flex flex-col px-4', {
  variants: {
    titleContentWidth: {
      [ESectionTitleContentWidth.Sm]: 'max-w-[38rem]',
      [ESectionTitleContentWidth.Md]: 'max-w-[38.75rem]',
      [ESectionTitleContentWidth.Lg]: 'max-w-[52rem]',
      [ESectionTitleContentWidth.Xl]: 'max-w-[64rem]',
    },
    titleSpacingY: {
      [ETitleSpacing.Sm]: '',
      [ETitleSpacing.Base]: '',
      [ETitleSpacing.Md]: '',
      [ETitleSpacing.Lg]: '',
      [ETitleSpacing.Xxl]: '',
    },
    titleType: {
      [ESectionTitleType.A]: 'gap-3',
      [ESectionTitleType.B]: 'gap-4',
    },
    titleAlignContent: {
      [ESectionTitleAlignContent.Center]: 'mx-auto items-center',
      [ESectionTitleAlignContent.Start]: 'items-start',
    },
    hasTitleMargin: {
      true: '',
    },
  },
  compoundVariants: [
    {
      hasTitleMargin: true,
      titleSpacingY: ETitleSpacing.Sm,
      className: 'mb-6',
    },
    {
      hasTitleMargin: true,
      titleSpacingY: ETitleSpacing.Base,
      className: 'mb-8',
    },
    {
      hasTitleMargin: true,
      titleSpacingY: ETitleSpacing.Md,
      className: 'mb-6 2xl:mb-12',
    },
    {
      hasTitleMargin: true,
      titleSpacingY: ETitleSpacing.Lg,
      className: 'mb-8 2xl:mb-12',
    },
    {
      hasTitleMargin: true,
      titleSpacingY: ETitleSpacing.Xxl,
      className: 'mb-8 2xl:mb-16',
    },
  ],
});
export const descriptionCVA = cva(
  'font-regular block text-base leading-mega-loose',
  {
    variants: {
      theme: {
        [ESectionTitleTheme.SectionTitle1]: 'text-interface-800',
        [ESectionTitleTheme.SectionTitle2]: 'text-interface-800',
        [ESectionTitleTheme.SectionTitle3]: 'text-interface-50/70',
        [ESectionTitleTheme.SectionTitle4]: 'text-interface-50/70',
        [ESectionTitleTheme.SectionTitle5]: 'text-interface-50/70',
      },
      alignContent: {
        [ESectionTitleAlignContent.Center]: 'text-center',
        [ESectionTitleAlignContent.Start]: '',
      },
    },
  },
);

export const eyebrowsCVA = cva('', {
  variants: {
    type: {
      [ESectionTitleType.A]: '',
      [ESectionTitleType.B]: '',
    },
    theme: {
      [ESectionTitleTheme.SectionTitle1]: '',
      [ESectionTitleTheme.SectionTitle2]: '',
      [ESectionTitleTheme.SectionTitle3]: '',
      [ESectionTitleTheme.SectionTitle4]: '',
      [ESectionTitleTheme.SectionTitle5]: '',
    },
  },
  compoundVariants: [
    {
      type: ESectionTitleType.A,
      theme: [
        ESectionTitleTheme.SectionTitle1,
        ESectionTitleTheme.SectionTitle3,
      ],
      className: 'text-brand-500',
    },
    {
      type: ESectionTitleType.A,
      theme: ESectionTitleTheme.SectionTitle2,
      className: 'text-interface-800',
    },
    {
      type: ESectionTitleType.A,
      theme: [
        ESectionTitleTheme.SectionTitle4,
        ESectionTitleTheme.SectionTitle5,
      ],
      className: 'text-brand-50',
    },
    {
      type: ESectionTitleType.B,
      theme: [
        ESectionTitleTheme.SectionTitle1,
        ESectionTitleTheme.SectionTitle2,
        ESectionTitleTheme.SectionTitle3,
      ],
      className: 'text-interface-500',
    },
    {
      type: ESectionTitleType.B,
      theme: [
        ESectionTitleTheme.SectionTitle4,
        ESectionTitleTheme.SectionTitle5,
      ],
      className: 'text-brand-50',
    },
  ],
});
