import clsx from 'clsx';
import React from 'react';

import { EListVariant } from './List.constants';
import { RichText, TRichTextContent } from '../RichText';

export type TListProps = {
  listTitle?: string;
  list?: TRichTextContent;
  isOpenInNewTab?: boolean;
  variant: EListVariant;
};

export const List: React.FC<TListProps> = ({
  listTitle,
  list,
  isOpenInNewTab,
  variant = EListVariant.Numerous1,
}) => (
  <div
    className={clsx(
      'grid flex-col break-words  text-interface-800',
      'prose-h3:text-base prose-h3:font-semibold ',
      variant === EListVariant.NumerousNewsWebsite1
        ? 'my-4 gap-3 rounded-[1.25rem] border-[0.1875rem] border-brand-500 bg-brand-250 p-6 prose-h3:text-brand-500'
        : 'gap-2 py-4 prose-h3:text-interface-1000 ',
    )}
  >
    {listTitle ? <h3>{listTitle}</h3> : null}
    {!!list?.length && (
      <div className='empty:hidden prose-ol:grid prose-ol:max-w-lg prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-6 prose-ul:grid prose-ul:max-w-lg prose-ul:gap-2 prose-ul:ps-[1.375rem] before:prose-li:-start-[1.1rem] before:prose-li:h-1.5 before:prose-li:w-1.5'>
        <RichText isOpenInNewTab={isOpenInNewTab} content={list} />
      </div>
    )}
  </div>
);
